import React, { useState } from 'react';
import styled from 'styled-components';
import device from '../../theme';

const Form = styled.form`
  padding: 0;
`;

const Field = styled.div`
  margin: 22px 0;
`;

const Label = styled.label`
  font-size: 20px;
  color: #fff;
  font-weight: bold;
`;

const Input = styled.input`
  display: block;
  //width: 320px;
  background: none;
  padding: 8px 12px;
  font-family: 'PT Sans', sans-serif;
  border: 2px solid #e2e2e2;
  border-radius: 10px;
  margin: 4px 0;
  outline: none;
  @media ${device.mobile} {
    width: 100%;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #0a041a inset !important;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: white !important;
  }
`;

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  background: none;
  border: 2px solid #e2e2e2;
  font-family: 'PT Sans', sans-serif;
  border-radius: 10px;
  color: #fff;
  box-sizing: border-box;
  font-size: 18px;
  padding: 8px 12px;
  outline: none;
  margin: 4px 0;
  height: 200px;
  @media ${device.mobile} {
    width: 100%;
  }
`;

const Submit = styled.input`
  padding: 12px 22px;
  border-radius: 10px;
  font-size: 20px;
  background: #cc770e;
  color: #fff;
  outline: none;
  cursor: pointer;
  max-width: 320px;
  height: 100%;
  display: block;
`;

function ContactForm({ textareaLabel, addEvent = false }) {
  const [isSending, setSending] = useState(false);
  const [mail, setMail] = useState('');
  const [subject, setSubject] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [url, setUrl] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = () => {
    if (!addEvent) {
      if (!mail || !subject || !message) {
        setSending(false);
        return;
      }

      setSending(true);
      return;
    }

    if (!mail || !title || !date || !zipCode) {
      setSending(false);
      return;
    }

    setSending(true);
  };

  return (
    <Form action="https://formspree.io/xaydyjlr" method="POST">
      <Field>
        <Label>Din email</Label>
        <Input
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          type="email"
          name="Email"
          required
        />
      </Field>
      {!addEvent ? (
        <>
          <Field>
            <Label>Emne</Label>
            <Input
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              type="text"
              name="Emne"
              required
            />
          </Field>
          <Field>
            <Label>{textareaLabel || 'Feedback'}</Label>
            <TextArea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              type="text"
              name="Besked"
              required
            />
          </Field>
        </>
      ) : (
        <>
          <Field>
            <Label>Titel</Label>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              name="Titel"
              required
            />
          </Field>
          <Field>
            <Label>Dato</Label>
            <Input
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="text"
              name="Dato"
              required
            />
          </Field>
          <Field>
            <Label>Postnummer</Label>
            <Input
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              type="text"
              name="Postnummer"
              required
            />
          </Field>
          <Field>
            <Label>Link til begivenhed</Label>
            <Input
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              type="text"
              name="Link"
            />
          </Field>
        </>
      )}
      <Field>
        <Submit
          onClick={() => handleSubmit()}
          type="submit"
          value={
            addEvent ? 'Tilføj begivenhed' : isSending ? 'Sender...' : 'Send'
          }
        />
      </Field>
    </Form>
  );
}

export default ContactForm;
