import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';

const UL = styled.ul`
  margin: 22px 0;
  padding: 0;
  list-style-type: none;
`;

const LI = styled.li`
  font-family: 'PT Sans', sans-serif;
  color: #fff;
  margin: 8px 0;
`;

function ContactPage() {
  return (
    <>
      <SEO title="Kontakt os" />
      <Layout>
        <section className="explore-container">
          <h1>Kontakt os</h1>
          <UL>
            <LI>Midear</LI>
            <LI>CVR.: 41270713</LI>
            <LI>Banegårdsgade 1 3. 23</LI>
            <LI>8000 Aarhus C</LI>
            <LI>+45 24628694</LI>
            <LI>jk@midear.dk</LI>
          </UL>
          <ContactForm textareaLabel="Besked" />
        </section>
      </Layout>
    </>
  );
}

export default ContactPage;
